import React from "react";
import { Link } from "gatsby"
import Layout from "../../components/Layout";
import Section from "../../components/Section"
import SectionBanner from "../../components/SectionBanner";
import SectionOverview from "../../components/SectionOverview"
import Seo from "../../components/Seo";
import Image from "../../components/Image"
import ImageSVG from "../../components/ImageSVG"
import Button from "../../components/Button"
import Icon from "../../components/Icon"
import JoinOurTeamBGImage from '../../images/careers/careers--join-our-team.jpg'
import JobsListing from "../../components/JobsListing"


export default function CareersLanding() {

  return (
    <Layout id="careers-landing">
      <Seo
        title="Careers"
        description="It’s really quite simple. We could go on about our mission statement and defining brand pillars, but the truth is far less complex. At Bixal, we are inspired by the promise and potential of the world that surrounds us. We love getting the inside view on organizational challenges and coming up with bold, innovative ways to solve them."
        relPath={"/careers/"}
      />
      <div className="page--careers">

        <SectionBanner
          id={"careers-hero"}
          bgImage={"careers/careers-hero.jpg"}
          bgTextShade={true}
          title="Careers"
        />


        <Section
          id="careers-intro"
          constrain
          indent
          spacing={120}
        >
          <SectionOverview divider>
            <h2>How can you improve the lives of people today?</h2>
          </SectionOverview>

          <p>Bixal's diverse and talented team has led the digital transformation of some of the most influential organizations in the world and brings unparalleled experience and energy into every one of our engagements. Our team of supportive professionals thrive on challenge and hold themselves and their work to a higher standard.</p>
          <p>Come work with us.</p>
        </Section>


        <div className="careers-b-banner">
          <div
            className="careers-b-banner__visual-accent"
            role="presentation"
            aria-hidden="true"
          >
            <ImageSVG
              src="overlays/b-blue.svg"
              alt="blue Bixal B logo"
            />
          </div>

          <div className="careers-b-banner__image">
            <Image
              src="careers/careers--b-banner.jpg"
              alt="A man pointing to sticky notes on a white board."
            />
          </div>
        </div>


        <Section
          id="careers-how-we-work"
          constrain
          indent
          spacing={80}
        >
          <SectionOverview divider>
            <h2>How we work</h2>
          </SectionOverview>

          <div className="careers-how-we-work__columns">
            <div className="careers-how-we-work__column">
              <Image
                src={`careers/careers--thought-leaders.jpg`}
                alt={""}
              />

              <div className="column-content__text">
                <h3>Thought Leaders</h3>

                <p>
                  Bixal's team members are constant learners and teachers, supportive of
                  individual and professional growth. We value kindness and humility and
                  seek to build each other up and drive everyone forward.
                </p>
              </div>
            </div>

            <div className="careers-how-we-work__column">
              <Image
                src={`careers/careers--collaborative-and-agile.jpg`}
                alt={""}
              />

              <div className="column-content__text">
                <h3>Collaborative &amp; Agile</h3>

                <p>
                  We relentlessly focus on outcomes and weave it together with a unique
                  agility that permeates everything we do. Bixal values collaboration and
                  transparency. It shapes how we approach every project.
                </p>
              </div>
            </div>

            <div className="careers-how-we-work__column">
              <Image
                src={`careers/careers--empowered-teams.jpg`}
                alt={""}
              />

              <div className="column-content__text">
                <h3>Empowered Teams</h3>

                <p>
                  Bixal unites different people with different perspectives from all over
                  the world and provides them with an open, empowered environment where
                  creativity can flourish.
                </p>
              </div>
            </div>
          </div>
        </Section>


        <Section
            id={"careers-join-our-team"}
            spacing={260}
            bgColor={"#03c5bc"}
            bgTextShade={false}
            bgImage={JoinOurTeamBGImage}
            indent={true}
            constrain
          >
            <div className="careers-join-our-team__content">
              <SectionOverview divider>
                <h2>Join Our Team</h2>
              </SectionOverview>

              <p>
                We are focused on working with federal clients to deliver on their mission to leave
                a positive impact on the lives of people everywhere. Headquartered in Fairfax, VA,
                our talent is located in Washington, DC, Richmond, VA, Baltimore, MD, Atlanta, GA,
                and other remote locations.
              </p>

              <a className="careers__view-all-jobs-link" href="https://jobs.lever.co/bixal" rel="noreferrer" target="_blank">
                <Button type={"link"} >
                  View All Jobs
                  <Icon type={"rightArrow"}></Icon>
                </Button>
              </a>
            </div>
        </Section>


        <Section
          id="careers-featured-jobs"
          spacing={80}
          indent={true}
          constrain
        >
          <SectionOverview divider>
            <h2>Featured Jobs</h2>
          </SectionOverview>
          <JobsListing />
        </Section>


        <Section
          id={"careers-bixal-benefits"}
          spacing={180}
          bgColor={"#7D0096"}
          bgTextShade={false}
          indent={true}
          constrain
        >
          <SectionOverview divider>
            <h2>Bixal Benefits</h2>
          </SectionOverview>

          <ul className="careers-bixal-benefits__benefits-list">
            <li>Competitive base salary</li>
            <li>Flex hours / telework options</li>
            <li>Generous PTO</li>
            <li>10 paid holidays</li>
            <li>Medical/Dental/Vision Insurance</li>
            <li>New business referral bonus</li>
            <li>Professional development incentives</li>
            <li>WMATA commuter benefits program</li>
            <li>Company provided Short-Term Disability</li>
            <li>Company provided Life Insurance</li>
            <li>401k with employer match</li>
          </ul>
        </Section>


        <div className="careers-get-to-know-us">
          <Section
            id="careers-get-to-know-us__content"
            constrain
            indent
            spacing={80}
          >
            <SectionOverview
              align="center"
              divider>
              <h2>Get to Know Us</h2>
            </SectionOverview>

            <p>Our team brings an unmatched creative energy to our federal clients, and our inclusive culture is what makes it all possible.</p>
            <p>Our culture is felt the moment you walk in the door, and it is reflected across our entire team.</p>

            <div className="careers-get-to-know-us__cta">
              <Link to="/about/">
                <Button type={"link"}>
                  Learn About Team Bixal <Icon type={"rightArrow"} color="#000"></Icon>
                </Button>
              </Link>
            </div>
          </Section>

          <div className="careers-get-to-know-us__banner">
            <Image
              src="careers/careers--get-to-know-us.jpg"
              alt="A mosaic of three photos of Bixal employees smiling."
            />
          </div>
        </div>

      </div>
    </Layout>
  )
}
