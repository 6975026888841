import React, { useEffect, useState } from 'react'
import DecorativeLink from './DecorativeLink'
import TopicSubhead from './TopicSubhead'
import Heading from './Heading'
import Spinner from './Spinner'
import Button from './Button'
import Icon from './Icon'

const Jobs = ({ jobs }) => {
  return (
    <>
      {jobs.map(job => {
        return (
          <div className="emphasis-block" key={job.id}>
            <TopicSubhead>{job.categories.team}</TopicSubhead>

            <Heading hasLine={false}>
              <DecorativeLink href={`https://jobs.lever.co/bixal/` + job.id}>
                {job.text}
              </DecorativeLink>
            </Heading>

            <div className="emphasis-block__text">
              {job.categories.location}
            </div>
          </div>
        )
      })}
    </>
  )
}

const JobsListing = () => {
  const [jobs, setJobs] = useState([])
  const [latestJobs, setLatestJobs] = useState(jobs)
  const [isLoading, setIsLoading] = useState(true)

  const fetchJobs = async () => {
    const teams = [
      'Corporate Operations',
      'Data Analytics',
      'Human Centered Design',
      'International',
      'Learning',
      'Program Management',
      'Technology',
      'Other Teams',
    ]

    return await teams.reduce(async (previousPromise, team) => {
      let jobsArray = await previousPromise

      const jobPostingsCall = await fetch(
        `https://api.lever.co/v0/postings/bixal?department=${encodeURI(team)}`
      )

      const jobPostings = await jobPostingsCall.json()

      jobPostings.length > 0 &&
        jobsArray.push({
          team,
          jobPostings,
        })

      return jobsArray
    }, Promise.resolve([]))
  }

  useEffect(() => {
    let mounted = true // we can't update state on an unmounted component so we place a clean up function to ask if the component is still mounted

    fetchJobs()
      .then(resultJobs => {
        if (mounted) {
          setJobs([...resultJobs])
        }
      })
      .catch(function (err) {
        console.log(`Error: ${err}`)
      })

    return function cleanup() {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let handleMostRecent = async () => {
      if (jobs.length) {
        setLatestJobs(
          jobs.map(team => {
            return team?.jobPostings.reduce((a, b) =>
              a.createdAt > b.createdAt ? a : b
            )
          }, await Promise.resolve().finally(setIsLoading(false)))
        )
      }
    }
    handleMostRecent()
  }, [jobs])

  return isLoading ? (
    <Spinner
      data-test-id="loading-jobs"
      spin={isLoading}
      backgroundColor={'white'}
    >
      Loading Jobs&hellip;
    </Spinner>
  ) : (
    <>
      <div
        data-test-id="job-listing"
        className="careers-featured-jobs__emphasis-blocks emphasis-block-set"
      >
        {latestJobs
          .reduce((accumulator, currentValue, currentIndex, array) => {
            if (currentIndex % 2 === 0)
              accumulator.push(array.slice(currentIndex, currentIndex + 2))
            return accumulator
          }, [])
          .map((jobset, i) => {
            // styles only want two jobs in a row
            return (
              <div className="emphasis-block-set__row" key={`blockset-${i}`}>
                <Jobs jobs={jobset} />
              </div>
            )
          })}
      </div>
      <div className="careers-featured-jobs__cta">
        <a
          className="careers__jobs-link"
          href="https://jobs.lever.co/bixal"
          rel="noreferrer"
          target="_blank"
        >
          <Button type={'link'}>
            View All Jobs
            <Icon type={'rightArrow'}></Icon>
          </Button>
        </a>
      </div>
    </>
  )
}

export default JobsListing
