import React from 'react';

function TopicSubhead({ id, children }){ 
  if (!children) {
    return '';
  }

  return (
    <div className={`topic-subhead`} id={id || null}> 
      <p>
        { children }
      </p>
    </div>
  )
}

export default TopicSubhead;
