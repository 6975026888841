import React from "react";
import PropTypes from 'prop-types';
import { themeConfig } from '../utils/themeConfig'
import hexToRgba from 'hex-to-rgba'
import useHover from '../utils/useHover'
import styled from "styled-components"

// build rgba value
const underlineColour = ({ lineColor, opacity }) => {
  return hexToRgba(
    !lineColor ? themeConfig.color.navInteract : lineColor,
    !opacity ? 0.5 : opacity
  )
}

//styles
const UnderlineLink = styled.span`
  a {
    text-decoration: none;
    padding: 0px 4px;
    box-sizing: border-box;
    box-shadow: inset 0 ${props => (props.action !== undefined ? `-100px` : `-2px`)} 0
      ${underlineColour};
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
  }
`

function DecorativeLink({ id, lineColor, opacity, href, children, ...linkAttr }) {
  // envoking our hover event
  const [hoverRef, isHovered] = useHover();
  // build our class list
  const classList = id
    ? `decorative-link decorative-link--${id}`
    : "decorative-link"
  // if it is a React element, like a Gatsby Link, we need to return the object
  const childrenWithClass = React.Children.map(children, (child, i) =>
    React.cloneElement(<UnderlineLink>{child}</UnderlineLink>, {
      className: classList,
      action: isHovered ? isHovered.toString() : undefined,
      ref: hoverRef,
    })
  )

  return href ? (
    <UnderlineLink
      action={isHovered ? isHovered.toString() : undefined}
      lineColor={lineColor}
      opacity={opacity}
    >
      <a
        className={classList}
        id={id}
        rel="noreferrer"
        target="_blank"
        href={href}
        {...linkAttr}
        ref={hoverRef}
      >
        {children}
      </a>
    </UnderlineLink>
  ) : (
    childrenWithClass
  )}

DecorativeLink.propTypes = {
  id: PropTypes.string,
  lineColor: PropTypes.string,
  opacity: PropTypes.number,
  href: PropTypes.string,
  action: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
};

export default DecorativeLink;

