function themeConfig() {
  return {
    'color': {
      'navInteract': '#7d0096',
      'bxColors' : {
        'purple': "#7d0096",
        'orange': "#fda305",
        'teal': "#06c5bc",
        'maroon': "#55052a",
        'navy': "#172d6a",
        'red': "#e02f00",
      },
    }
  }
}

module.exports = {
  themeConfig: themeConfig()
};
