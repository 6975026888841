import React from 'react';

function Heading({ id, headingLevel, hasLine, lineColor, children }){ 
  if (isNaN(headingLevel)) {
    headingLevel = '2';
  }

  if (!lineColor) {
    lineColor = 'black';
  }

  const HeadingTag = 'h'+ headingLevel;

  if (!children) {
    return '';
  }

  let classNames = 'heading';

  if (hasLine) {
    classNames += ' heading--underlined';
  }

  return (
    <div className={`${classNames}`} id={id || null}> 
      <HeadingTag className="heading__content">
        { children }
      </HeadingTag>
      { hasLine ? (
        <div className="heading__line" style={{ backgroundColor: lineColor }}></div>
      ) : '' }
    </div>
  )
}

export default Heading;
